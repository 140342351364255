<template>
  <slot />
</template>

<script>
export default {
  name: 'AppLayoutAuth'
};
</script>

<style scoped>
</style>
